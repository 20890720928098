import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import {
  getCopyrightMessage,
  isAuthorizedContent,
  ContentPreviewPageData,
  contentPreviewResponseToData,
} from '@/utilities/cmsUtilities';
import APP_CONST from '@/constants/AppConst';
import axios from 'axios';
import {getContentfulGraphQLQuery, getRelatedContentfulGraphQLQuery} from '@/utilities/contentfulGraphQLHelpers';
import CmsNotAuthorizedComponent from '@/commoncomponents/CmsNotAuthorizedComponent/CmsNotAuthorizedComponent.vue';
import CmsLabelComponent from '@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue';
import CmsTagComponent from '@/commoncomponents/CmsTagComponent/CmsTagComponent.vue';
import CmsCardCarousel from '@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue';
import CmsContentfulComponent from '@/commoncomponents/CmsContentfulComponent/CmsContentfulComponent.vue';

@Component({
  name: 'content-preview-landing-page',
  components: {
    CmsCardCarousel,
    'bouncing-preloader': BouncingPreloaderComponent,
    'cms-not-authorized': CmsNotAuthorizedComponent,
    'cms-content-label': CmsLabelComponent,
    'cms-contentful-render': CmsContentfulComponent,
    'cms-tag-list': CmsTagComponent
  }
})
export default class ContentPreviewLandingPage extends Vue {
  contentId: string = '';
  loading: boolean = false;
  isAuthorized: boolean = false;
  pageData: ContentPreviewPageData | null = {
    id: '',
    title: '',
    itemDescription: '',
    itemShortDescription: '',
    heroImage: '',
    labelData: [],
    releaseDate: '',
    marketingContent: {},
    relatedContent: {}
  };

  @Prop()
  contentIdQuery: string | undefined;

  async fetchData() {
    const url = `${APP_CONST.CONTENTFUL_GRAPHQL_URL}${APP_CONST.CONTENTFUL_GRAPHQL_SPACE}${APP_CONST.CONTENTFUL_GRAPHQL_ENV}`;
    const headers = { Authorization: `Bearer ${APP_CONST.CONTENTFUL_GRAPHQL_TOKEN}` };
    const method = 'post';
    this.loading = true;
    try {
      const result = await axios({
        url,
        headers,
        method,
        data: {
          query: getContentfulGraphQLQuery('contentPreview', this.contentId)
        }
      });
      const relatedResult = await axios({
        url,
        headers,
        method,
        data: {
          query: getRelatedContentfulGraphQLQuery('contentPreview', this.contentId, false)
        }
      });
      const structuredData = contentPreviewResponseToData({ ...result, related: { ...relatedResult } });
      this.isAuthorized = isAuthorizedContent(structuredData);
      this.pageData = { ...structuredData };
      this.loading = false;
    }
    catch (err) {
      console.error(`Failed to fetch landing page`, err);
    }
  }

  async handleUpdatedId() {
    this.contentId = this.$route.query.id as string || '';
    await this.fetchData();
  }

  getCopyrightMessage = getCopyrightMessage;

  async beforeMount() {
    this.contentId = this.$route.query.id as string || '';
    this.$watch('$route', this.handleUpdatedId);
    await this.fetchData();
  }
}
